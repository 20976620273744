import { useEffect } from 'react';

import './App.css';
import left from './img/left.png'
import right from './img/right.png'
import bottom from './img/bottom.png'
import book from './img/gaybcs.png'
import rainbow_logo from './img/rainbow_crackle_logo.png'

function App() {

  useEffect(() => {
    if (window.instgrm)
        window.instgrm.Embeds.process();
    }, []);

  return (
    <div className="App">
      <div className='header'>
        <div className='presents_container'>
            <p className='right-align'>From the deepest depths of naughty humor</p>
            <img className="presents_image" src={rainbow_logo} alt="logo" />
            <p>is proud to bring you:</p>
        </div>
      </div>
      <div className="page_content">
        <div className="main_banner">
          <div className="book_content_container">
          <img className="left_border_img" src={left} alt="left_border"/>
          <img className="right_border_img" src={right} alt="right_border"/>
            <div className="main_banner_content">
              <img className="main_banner_book" src={book} alt="book"/>
              <p className="book_tagline">
                <span style={{color:"red"}}>My </span>
                <span style={{color:"orange"}}>First </span>
                <span style={{color:"#ffd800"}}>Grown-</span>
                <span style={{color:"lime"}}>Up </span>
                <span style={{color:"cyan"}}>Gay </span>
                <span style={{color:"#247fff"}}>B </span>
                <span style={{color:"#a40aff"}}>C'</span>
                <span style={{color:"#ff0089"}}>s <br></br></span>
                <span className="book_subtag">(A super gay, wildly inappropriate, NSFW XXX Adult Picture book)</span></p>
              <div className="book_button_container">
                  <a target="_blank"  rel="noreferrer" href="https://www.amazon.com/My-First-Grown-Up-Gay-Cs/dp/B0CKKJGCLJ/ref=sr_1_fkmr1_2?crid=3V3U72NZW5KOM&keywords=gay+bcs+chaotic+top&qid=1697339610&sprefix=gaybcs+chaotic+top%2Caps%2C93&sr=8-2-fkmr1" className="book_button preorder_button">Pre-Order on Amazon!</a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="main_banner">
          <div className="quotes_container">
            <p className="quote" style={{color:"red"}}>
              Every <span style={{fontSize:"125%"}}>Gay</span> guy needs to have this book! Go <span style={{fontSize:"125%"}}>buy it</span> right now! <br></br>- DomDaddy69
            </p>
            <p className="quote" style={{color:"orange",textAlign:"left"}}>
              It's the <span style={{fontSize:"125%"}}>perfect</span> coffee table conversation piece for my gay brunches & <span style={{fontSize:"135%"}}>weekend orgies</span><br></br> - CumSluttty
            </p>
            <p className="quote" style={{color:"#ffd800",textAlign:"right"}}>
              If you had this book I'd date you<span style={{fontSize:"135%"}}><br></br> - Your Crush</span>
            </p>
            <p className="quote last_quote" style={{color:"lime",fontSize:"4rem"}}>
              Ok but for real, this book is funny and perfect to read to your (adult) friends. It's Gayyy to the maxxx! <br></br> - The Chaotic Top & Son
            </p>
          </div>
        </div>
        <div className="main_banner">
          <div className="info_container">
            <p className="parg" style={{color:"black"}}>
            <span style={{color:"cyan",fontSize:"155%", fontFamily: 'Fjalla'}}>A is for Anal Beads all up in my ass,</span> <br></br>B is for buy this book right now it is the best! <br></br><br></br>This is the Hilarious Gay Book you can't BEAR to put down that will put a TWINKle in your eye!! Display it on the EDGE of your fabulous iced coffee table, have a read-aloud when you HOST your next gay brunch, take it with you when you TRAVEL or just CRUISE through the pages while you're waiting for the water to run clear. We can't describe exactly what's in it because it's so DIRTY we have to be DISCREET and keep it on the DL. But every page will make you laugh LONG and HARD. It's not a DEEP or THICK book but you will still find it extremely satisfying. Reading it is such a piece of CAKE you'll want to read the wHOLE thing until you FINISH. So if you're LOOKING to get HOOKED UP with a very gay, very funny, very NSFW book, you'll love every VERSe that's inSIDE from the TOP to the BOTTOM.
            </p>
          </div>
          <div className="info_container">
            <p className="parg" style={{color:"black"}}>
            <span style={{color:"#ff0089",fontSize:"155%", fontFamily: 'Fjalla'}}>About The Author: </span>When a little gay boy was adopted by the Chaotic Top he became their Chaotic Top-Son, and then the true chaos began. To show his appreciation The Chaotic Top's Chaotic Top-Son created this book and gave it to The Chaotic Top as a gift. The book was proudly displayed on their coffee table, and ever since then every gay that saw it said "I need need neeeed this book." Based on this totally scientific and extensive marketing research, it is now for sale for you! But anyway, all you really need to know about The Chaotic Top's Chaotic Top-Son is what's written on the back cover of the book.
            </p>
          </div>
        </div>
      </div>
      <div className="footer">
        {/* <div className="instagrams_container">
          <div className="instagram">
            <InstaProfile
              username={"thechaotictop"}
              display_name={"The Chaotic Top"}
            />
          </div>
          <div className="instagram">
            <InstaProfile
              username={"shes.a.chaotic.top"}
              display_name={"Chaotic Top Memes"}
            />
          </div>
        </div> */}
        <img className="footer_image" src={bottom} alt="footer" />
      </div>
      <script async src="http://www.instagram.com/embed.js" type="text/javascript"></script>
    </div>
  );
}

export default App;
